<template>
  <div class="chroma">
    <div class="leftRight"></div>
    <div class="topBottom"></div>

    <transition-group appear @leave="moderatedLeave" class="texts">
      <div v-for="data in questions" :key="data.id" class="text-wrapper"
        :style="{ borderColor: `#${data.createdBy?.color || '000'} ` }">
        <player-avatar v-if="!data.externalUser?.enabled" :player="data.createdBy" :scale="3" :showUsername="false"
          :horizontal="true" />
        <div v-if="data.externalUser?.enabled" class="playerAvatarContainer">
          <img :src="data.externalUser.avatarUrl" class="externalUserAvatar" />
        </div>
        <div>
          <div class="username">{{ data.externalUser?.enabled ? data.externalUser.name : data.createdBy.username }}</div>
          <div>Envió una pregunta al orador</div>
        </div>
      </div>
    </transition-group>

    <transition appear @before-enter="moderatedBeforeEnter" @enter="moderatedEnter" @leave="moderatedLeave">
      <div v-if="acceptedQuestion" class="moderationText">
        <template v-if="acceptedQuestion.externalUser?.enabled">
          <!-- Usuario externo a douob -->
          <div class="playerAvatarContainer">
            <img :src="acceptedQuestion.externalUser.avatarUrl" class="externalUserAvatar" />
          </div>
          <div class="username">{{ acceptedQuestion.externalUser.name }}</div>
        </template>
        <template v-else>
          <!-- Usuario douob -->
          <player-avatar :player="acceptedQuestion.createdBy" :scale="5" :showUsername="true" />
        </template>
        <div class="text">{{ acceptedQuestion.question }}</div>
      </div>
    </transition>

    <screenReactions />
  </div>
</template>

<script>
import gsap from "gsap";
// import PlayerAvatar from "../PlayerAvatar.vue";
import screenReactions from "../components/screens/screenReactions.vue";

export default {
  components: {
    screenReactions,
  },
  data() {
    return {
      background: {
        color: "green"
      },
      questions: [],
      acceptedQuestion: null,
      duracionPlayerEnvioPregunta: 30, // en segundos
    };
  },
  sockets: {
    PreguntaFake(data) {
      // var { question } = data;
      console.log('fake', data)
      data.time = Date.now();
      this.questions.push(data);
    },
    QuitarPreguntaActual() {
      console.log("QuitarPreguntaActual")
      this.acceptedQuestion = null
    },
    newModeratorQuestion(data) {
      var { question } = data;
      console.log("newModeratorQuestion", question)
      question.time = Date.now();
      this.questions.push(question);
    },
    approvedModeratorQuestion(data) {
      const { question } = data;
      console.log("newAcceptedQuestion", question)
      if (this.acceptedQuestion == null) {
        this.acceptedQuestion = question;
      } else {
        // Si ya habia una pregunta borramos la existente y agregamos la nueva con un timer
        this.acceptedQuestion = null;
        setTimeout(() => {
          this.acceptedQuestion = question;
        }, 1500);
      }
    },
    RemoveCurrentQuestion() {
      this.acceptedQuestion = null;
    },
    connect() {
      // if (this.connectedOnce) window.location.reload() // Si estoy reconectando, recargar la pagina
      
      // this.connectedOnce = true
      console.log("socket.io connect")
      
      // const spaceSlug = this.$route.params.slug
      console.log("****")
      this.$socket.client.emit('space:JoinChroma');
      // this.$store.commit("StopLoading");
      // this.$socket.client.emit("space:LoginServer", { spaceSlug, api: "bla" })
      this.$store.commit("StopLoading", null, { root: true })

    },
  },
  methods: {
    moderatedBeforeEnter(el) {
      el.style.transform = "translateY(0) translateX(-50%)";
    },
    moderatedEnter(el) {
      gsap.to(el, {
        y: '-50%',
        duration: .5,
        ease: "power1.out"
      })
    },
    moderatedLeave() { },
    LimpiarListaEnvioPregunta() {
      const borrar = this.questions.filter(x => x.time - Date.now() < (this.duracionPlayerEnvioPregunta * -1000))
      borrar.forEach((item) => {
        this.questions.splice(this.questions.indexOf(item), 1)
      })
      setTimeout(() => {
        this.LimpiarListaEnvioPregunta()
      }, 300)
    }
  },
  mounted() {
    this.LimpiarListaEnvioPregunta()
    if (!this.$socket.client.connected) {
        console.log("opening socket connection")
        this.$socket.client.open()
      }
  },
};
</script>


<style>
html{
  background-color: rgba(0,0,0,0);
}
#room{
  background-color: rgba(0,0,0,0);
}
.app-footer {
  display: none;
}
</style>
<style lang="scss" scoped>
@import '@/styles/variables.scss';

.chroma {
  height: 100vh;

  $border: solid 1px rgba(0, 128, 0, 0.4);
  background-color: rgba(0,0,0,0);

  .leftRight {
    display: block;
    width: 700px;
    height: 100vh;
    position: absolute;
    right: 0;
    top: 0;
    border-left: $border;
  }

  .topBottom {
    display: block;
    width: 1220px;
    height: 50vh;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: $border;
  }

  .texts {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    z-index: 9;
    display: flex;
    height: 28rem;
    flex-direction: column;
    justify-content: flex-end;
    gap: 1rem;
    overflow: hidden;

    .text-wrapper {
      display: flex;
      align-items: flex-start;
      gap: 0.5em;
      padding: .9em 1.8em .8em .7em;
      border-radius: 10px;
      width: 30rem;
      background: $white;
      font-size: 1.5rem;
      border-style: solid;
      border-width: 4px;
      transition: all .5s;
      z-index: 0;

      .username {
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        font-size: 21px;
        line-height: 1;
        text-transform: uppercase;
      }

      .categorie {
        width: 22rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre;
        font-size: 1.25rem !important;
        color: $grey;
      }
    }
  }

  .moderationText {
    position: absolute;
    left: 600px;
    top: 280px;
    width: 600px;
    background: $white;
    padding: 2em 2em 1em 2em;
    gap: 1em;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    font-size: 1.5rem;
    transition: all .5s;

    .playerAvatarContainer {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
    }

    .text {
      padding-top: 1em;
      font-size: 31px;
      line-height: 37px;
      text-align: center;
      font-weight: 500;
    }
  }
}

.username {
  text-transform: uppercase;
}

.externalUserAvatar {
  width: 100px;
  border-radius: 50%;
  border: 2px solid hsl(0deg, 0%, 98%);
  position: relative;
  aspect-ratio: 1;
}
</style>
<style lang="scss">
.chroma {
  .moderationText {
    .username {
      width: auto !important;
      font-family: 'Poppins', sans-serif;
      font-size: 21px;
      font-weight: 700;
    }
  }
}
</style>